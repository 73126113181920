import { render, staticRenderFns } from "./DataTableTaskFiles.vue?vue&type=template&id=beb1d65c&scoped=true&"
import script from "./DataTableTaskFiles.vue?vue&type=script&lang=js&"
export * from "./DataTableTaskFiles.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "beb1d65c",
  null
  
)

export default component.exports