import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
// const Register = () => import('@/views/pages/Register')

const Menus = () => import('@/views/menu/MenuIndex')
const EditMenu = () => import('@/views/menu/EditMenu')
const DeleteMenu = () => import('@/views/menu/DeleteMenu')

const MenuElements = () => import('@/views/menuElements/ElementsIndex')
const CreateMenuElement = () => import('@/views/menuElements/CreateMenuElement')
const EditMenuElement = () => import('@/views/menuElements/EditMenuElement')
const ShowMenuElement = () => import('@/views/menuElements/ShowMenuElement')
const DeleteMenuElement = () => import('@/views/menuElements/DeleteMenuElement')


//Carrier
const Carrier = () => import('@/views/carrier/Carrier')
const EditCarrier = () => import('@/views/carrier/EditCarrier')
const CreateCarrier = () => import('@/views/carrier/CreateCarrier')

//Driver
const Driver = () => import('@/views/driver/Driver')
const EditDriver = () => import('@/views/driver/EditDriver')
const CreateDriver = () => import('@/views/driver/CreateDriver')
const CreateFileDriver = () => import('@/views/driver/CreateFileDriver')
const EditFileDriver = () => import('@/views/driver/EditFileDriver')

//Dispatcher
const Dispatcher = () => import('@/views/dispatcher/Dispatcher')
const EditDispatcher = () => import('@/views/dispatcher/EditDispatcher')
const CreateDispatcher = () => import('@/views/dispatcher/CreateDispatcher')

//Administrator
const Administrator = () => import('@/views/administrator/Administrator')
const EditAdministrator = () => import('@/views/administrator/EditAdministrator')
const CreateAdministrator = () => import('@/views/administrator/CreateAdministrator')

// //Task
const Task = () => import('@/views/task/Task')
const EditTask = () => import('@/views/task/EditTask')
const CreateTask = () => import('@/views/task/CreateTask')

const MyTask = () => import('@/views/mytask/MyTask')
const EditMyTask = () => import('@/views/mytask/EditMyTask')

//LoginHistory
const LoginHistory = () => import('@/views/loginhistory/LoginHistory')

// //BrowsingHistory
const BrowsingHistory = () => import('@/views/browsinghistory/BrowsingHistory')
const EditBrowsingHistory = () => import('@/views/browsinghistory/EditBrowsingHistory')


// //Client
const Client = () => import('@/views/client/Client')
const EditClient = () => import('@/views/client/EditClient')
const CreateClient = () => import('@/views/client/CreateClient')


// //Settings
// const Settings = () => import('@/views/settings/Settings')
//
// //Dictionary
const Dictionary = () => import('@/views/dictionary/Dictionary')
const EditDictionary = () => import('@/views/dictionary/EditDictionary')
const CreateDictionary = () => import('@/views/dictionary/CreateDictionary')

// My Profile
const MyProfile = () => import('@/views/myprofile/MyProfile')

// Configuration
const Configuration = () => import('@/views/configuration/Configuration')

/// OurSubsidiary
const OurSubsidiary = () => import('@/views/our_subsidiary/OurSubsidiary')
const EditOurSubsidiary = () => import('@/views/our_subsidiary/EditOurSubsidiary')
const CreateOurSubsidiary = () => import('@/views/our_subsidiary/CreateOurSubsidiary')

/// OurDriver
const OurDriver = () => import('@/views/our_driver/OurDriver')
const EditOurDriver = () => import('@/views/our_driver/EditOurDriver')
const CreateOurDriver = () => import('@/views/our_driver/CreateOurDriver')

/// OurCar
const OurCar = () => import('@/views/our_car/OurCar')
const EditOurCar = () => import('@/views/our_car/EditOurCar')
const CreateOurCar = () => import('@/views/our_car/CreateOurCar')

/// Carriers Driver
const EditCarrierDriver = () => import('@/views/carrier_driver/EditCarrierDriver')
const CreateCarrierDriver = () => import('@/views/carrier_driver/CreateCarrierDriver')

/// Carriers Car
const EditCarrierCar = () => import('@/views/carrier_car/EditCarrierCar')
const CreateCarrierCar = () => import('@/views/carrier_car/CreateCarrierCar')



Vue.use(Router)

let router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({y: 0}),
  routes: routes()
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("auth");
  const isAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isHide = to.matched.some((record) => record.meta.hideForAuth);

  if (isAuth && !loggedIn) {
    return next({path: "/login"});
  } else if (isHide && loggedIn) {
    return next({path: "/dashboard"});
  }
  next();
});

export default router

function routes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'menu',
          meta: {label: 'Menu'},
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: Menus,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit',
              meta: {label: 'Edit Menu'},
              name: 'EditMenu',
              component: EditMenu,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/delete',
              meta: {label: 'Delete Menu'},
              name: 'DeleteMenu',
              component: DeleteMenu,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'menuelement',
          meta: {label: 'MenuElement'},
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: ':menu/menuelement',
              component: MenuElements,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':menu/menuelement/create',
              meta: {label: 'Create Menu Element'},
              name: 'Create Menu Element',
              component: CreateMenuElement,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':menu/menuelement/:id',
              meta: {label: 'Menu Element Details'},
              name: 'Menu Element',
              component: ShowMenuElement,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':menu/menuelement/:id/edit',
              meta: {label: 'Edit Menu Element'},
              name: 'Edit Menu Element',
              component: EditMenuElement,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':menu/menuelement/:id/delete',
              meta: {label: 'Delete Menu Element'},
              name: 'Delete Menu Element',
              component: DeleteMenuElement,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'carriers',
          meta: {label: 'Przewoźnicy'},
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: Carrier,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'create',
              meta: {label: 'Utwórz przewoźnika'},
              name: 'Utwórz przewoźnika',
              component: CreateCarrier,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit',
              meta: {label: 'Edytuj przewoźnika'},
              name: 'Edytuj przewoźnika',
              component: EditCarrier,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit/carrierr-drivers/create',
              meta: {label: 'Dodaj kierowcę'},
              name: 'Dodaj kierowcę przewoźnika',
              component: CreateCarrierDriver,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit/carrierr-drivers/create/:idFile/edit',
              meta: {label: 'Edytuj kierowcę'},
              name: 'Edytuj kierowcę przewoźnika',
              component: EditCarrierDriver,
              meta: {
                requiresAuth: true
              }
            },


            {
              path: ':id/edit/carrierr-cars/create',
              meta: {label: 'Dodaj samochód'},
              name: 'Dodaj samochód przewoźnika',
              component: CreateCarrierCar,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit/carrierr-cars/create/:idFile/edit',
              meta: {label: 'Edytuj samochód'},
              name: 'Edytuj samochód przewoźnika',
              component: EditCarrierCar,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },

        {
          path: 'drivers',
          meta: {label: 'Kierowcy'},
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: Driver,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'create',
              meta: {label: 'Utwórz kierowcę'},
              name: 'Utwórz kierowcę',
              component: CreateDriver,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit',
              meta: {label: 'Edytuj kierowcę'},
              name: 'Edytuj kierowcę',
              component: EditDriver,
              meta: {
                requiresAuth: true
              },

              children: [
                // {
                //   path: 'file/create',
                //   meta: {label: 'Dodaj plik kierowcy'},
                //   name: 'Dodaj plik kierowcy',
                //   component: CreateFileDriver,
                //   meta: {
                //     requiresAuth: true
                //   }
                // },
                // {
                //   path: 'file/:id/edit',
                //   meta: {label: 'Edytuj plik kierowcy'},
                //   name: 'Edytuj plik kierowcy',
                //   component: CreateFileDriver,
                //   meta: {
                //     requiresAuth: true
                //   }
                // }
              ]


            },
            {
              path: ':id/edit/file/create',
              meta: {label: 'Dodaj plik kierowcy'},
              name: 'Dodaj plik kierowcy',
              component: CreateFileDriver,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit/file/:idFile/edit',
              meta: {label: 'Edytuj plik kierowcy'},
              name: 'Edytuj plik kierowcy',
              component: EditFileDriver,
              meta: {
                requiresAuth: true
              }
            }


          ]
        },
        {
          path: 'dispatchers',
          meta: {label: 'Dyspozytorzy'},
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: Dispatcher,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'create',
              meta: {label: 'Utwórz dyspozytora'},
              name: 'Utwórz dyspozytora',
              component: CreateDispatcher,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit',
              meta: {label: 'Edytuj dyspozytora'},
              name: 'Edytuj dyspozytora',
              component: EditDispatcher,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'administrators',
          meta: {label: 'Administratorzy'},
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: Administrator,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'create',
              meta: {label: 'Utwórz administratora'},
              name: 'Utwórz administratora',
              component: CreateAdministrator,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit',
              meta: {label: 'Edytuj administratora'},
              name: 'Edytuj administratora',
              component: EditAdministrator,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'tasks',
          meta: { label: 'Zlecenia' },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: Task,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'create',
              meta: { label: 'Utwórz zlecenie' },
              name: 'Utwórz zlecenie',
              component: CreateTask,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit',
              meta: { label: 'Edytuj zlecenie' },
              name: 'Edytuj zlecenie',
              component: EditTask,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'mytasks',
          meta: { label: 'Moje zlecenia' },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: MyTask,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit',
              meta: { label: 'Edytuj zlecenie' },
              name: 'Edytuj moje zlecenie',
              component: EditMyTask,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'clients',
          meta: { label: 'Klienci' },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: Client,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'create',
              meta: { label: 'Utwórz klienta' },
              name: 'Utwórz klienta',
              component: CreateClient,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit',
              meta: { label: 'Edytuj klienta' },
              name: 'Edytuj klienta',
              component: EditClient,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'dictionaries',
          meta: { label: 'Słowniki' },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: Dictionary,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'create',
              meta: { label: 'Dodaj rekord dla słownika' },
              name: 'Dodaj rekord dla słownika',
              component: CreateDictionary,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit',
              meta: { label: 'Edytuj rekord dla słownika' },
              name: 'Edytuj rekord dla słownika',
              component: EditDictionary,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'browsing-history',
          meta: { label: 'Historia przeglądania' },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: BrowsingHistory,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit',
              meta: { label: 'Podgląd zdarzenia' },
              name: 'Podgląd zdarzenia',
              component: EditBrowsingHistory,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'login-history',
          meta: {label: 'Historia logowania'},
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: LoginHistory,
              meta: {
                requiresAuth: true
              }
            }
          ]
        },
        {
          path: 'myprofile',
          meta: {label: 'Mój profil'},
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: MyProfile,
              meta: {
                requiresAuth: true
              }
            }
          ]
        },{
          path: 'configuration',
          meta: {label: 'Konfiguracja'},
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: Configuration,
              meta: {
                requiresAuth: true
              }
            }
          ]
        },
        // {
        //   path: 'settings',
        //   meta: { label: 'Ustawienia' },
        //   component: {
        //     render(c) {
        //       return c('router-view')
        //     }
        //   },
        //   children: [
        //     {
        //       path: '',
        //       component: Settings,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     }
        //   ]
        // },


        {
          path: 'out-cars',
          meta: {label: 'Nasze pojazdy'},
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: OurCar,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'create',
              meta: {label: 'Utwórz pojazd'},
              name: 'Utwórz pojazd',
              component: CreateOurCar,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit',
              meta: {label: 'Edytuj pojazd'},
              name: 'Edytuj pojazd',
              component: EditOurCar,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        // {
        //   path: 'our-drivers',
        //   meta: {label: 'Nasi kierowcy'},
        //   component: {
        //     render(c) {
        //       return c('router-view')
        //     }
        //   },
        //   children: [
        //     {
        //       path: '',
        //       component: OurDriver,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //     {
        //       path: 'create',
        //       meta: {label: 'Utwórz kierowcę'},
        //       name: 'Nowy kierowca',
        //       component: CreateOurDriver
        //       ,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //     {
        //       path: ':id/edit',
        //       meta: {label: 'Edytuj kierowcę'},
        //       name: 'Edycja kierowcy',
        //       component: EditOurDriver,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //   ]
        // },
        {
          path: 'our-subsidiaries',
          meta: {label: 'Nasze oddziały'},
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: OurSubsidiary,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'create',
              meta: {label: 'Utwórz oddział'},
              name: 'Utwórz oddział',
              component: CreateOurSubsidiary,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: ':id/edit',
              meta: {label: 'Edytuj oddział'},
              name: 'Edytuj oddział',
              component: EditOurSubsidiary,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },

      ]
    },
    // {
    //   path: '/',
    //   redirect: '/login',
    //   name: 'Auth',
    //   component: {
    //     render(c) {
    //       return c('router-view')
    //     }
    //   },
    //   children: [
    //     {
    //       path: 'login',
    //       name: 'Login',
    //       component: Login
    //     }
    //   ]
    // },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '*',
      name: '404',
      component: Page404
    },
    {
      path: '*',
      name: '500',
      component: Page500
    }
  ]

}
