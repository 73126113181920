import 'core-js/stable'
import Vue from 'vue'
import CoreuiVue from '@coreui/vue'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import vuexI18n from 'vuex-i18n';
import Locales from './locale/vue-i18n-locales.generated.js';
// import "bootstrap";
// import "bootstrap/dist/css/bootstrap.css";
import DataTable from './components/DataTable'
import DataTableNoneOptions from './components/DataTableNoneOptions'
import DataTableNoneOptionsAndSearch from './components/DataTableNoneOptionsAndSearch'
import DataTableUserFiles from './components/DataTableUserFiles'
import DataTableTaskFiles from './components/DataTableTaskFiles'
Vue.component("DataTable", DataTable);
Vue.component("DataTableNoneOptions", DataTableNoneOptions);
Vue.component("DataTableNoneOptionsAndSearch", DataTableNoneOptionsAndSearch);
Vue.component("DataTableUserFiles", DataTableUserFiles);
Vue.component("DataTableUserFilesDriverLicence", DataTableUserFiles);
Vue.component("DataTableTaskFiles", DataTableTaskFiles);

import 'core-js/stable'; // only stable feature also is possible with only `core-js`
import 'regenerator-runtime/runtime'; // To ensure that regeneratorRuntime is defined globally

import Vidle from 'v-idle'
Vue.use(Vidle)

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDown,
  faAnglesDown,
  faAngleUp,
  faMinus,
  faPlus,
  faSearch,
  faSearchPlus, faTrash,
  faUserSecret,
  faCogs,
  faCog,
  faUserCog,
  faGears,
  faUsers,
  faServer,
  faHouseUser,
  faClipboard


} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faAngleUp);
library.add(faAngleDown);
library.add(faSearch);
library.add(faSearchPlus);
library.add(faPlus);
library.add(faMinus);
library.add(faUserSecret);
library.add(faTrash);
library.add(faCogs);
library.add(faCog);
library.add(faUserCog);
library.add(faGears);
library.add(faUsers);
library.add(faServer);
library.add(faHouseUser);
library.add(faClipboard);

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.prototype.$apiAdress = 'https://earmon.com.pl'
Vue.use(CoreuiVue)
Vue.use(vuexI18n.plugin, store);
Vue.i18n.add('en', Locales.en);
Vue.i18n.add('pl', Locales.pl);
let locale = 'pl'
if(typeof localStorage.locale !== 'undefined'){
  locale = localStorage.getItem("locale")
}
Vue.i18n.set(locale);

Vue.prototype.$tokenCheck = true;
Vue.config.productionTip = true;

new Vue({
  el: '#app',
  router,
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  template: '<App/>',
  components: {
    App, DataTable, FontAwesomeIcon
  },
  beforeCreate: function () {
    // eslint-disable-next-line no-empty
    if(localStorage.getItem('api_token') === ''){
      localStorage.setItem("auth", "");
      localStorage.setItem('api_token', '');
      localStorage.setItem('earmon_session', '');
      localStorage.removeItem('auth');
      localStorage.removeItem('api_token');
      localStorage.removeItem('earmon_session');
      self.$router.push({path: '/login'});
    }
  }
})

