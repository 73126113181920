export default {
    "en": {
        "forms":{
          "xxxx1": "wersja en",
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": "The password is incorrect.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        },
        "days": {
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thrusday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday",
            "sunday": "Sunday"
        },
        "dashboard": {
            "action": "Action",
            "another_action": "Another action",
            "something_else_here": "Something else here",
            "disabled_action": "Disabled action",
            "members_online": "Members online",
            "Traffic": "Traffic",
            "september": "September",
            "day": "Day",
            "month": "Month",
            "year": "Year",
            "users": "Users",
            "visits": "Visits",
            "unique": "Unique",
            "pageviews": "Pageviews",
            "new_users": "New Users",
            "bounce_rate": "Bounce Rate",
            "friends": "friends",
            "feeds": "feeds",
            "followers": "followers",
            "tweets": "tweets",
            "contacts": "contacts",
            "traffic_&_sales": "Traffic & Sales",
            "new_clients": "New Clients",
            "recurring_clients": "Recurring Clients",
            "organic": "Organic",
            "male": "Male",
            "female": "Female",
            "organic_search": "Organic Search",
            "user": "User",
            "country": "Country",
            "usage": "Usage",
            "payment_method": "Payment Method",
            "activity": "Activity",
            "new": "New",
            "recurring": "Recuring",
            "registered": "Registered",
            "last_login": "Last login",
            "time": {
                "sec_ago": "sec ago",
                "minutes_ago": "minutes ago",
                "hour_ago": "hour ago",
                "last_month": "Last month",
                "last_week": "Last week",
                "yesterday": "Yesterday"
            }
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "coreuiforms": {
            "notes": {
                "note": "Note",
                "notes": "Notes",
                "create_note": "Create Note",
                "add_note": "Add Note",
                "author": "Author",
                "title": "Title",
                "content": "Content",
                "applies_to_date": "Applies to date",
                "status": "Status",
                "note_type": "Note type"
            },
            "users": {
                "user": "User",
                "users": "Users",
                "username": "Username",
                "email": "E-mail",
                "roles": "Roles",
                "email_verified_at": "Email verified at"
            },
            "menu": {
                "menu_list": "Menu list",
                "add_new_menu": "Add new menu",
                "name": "Name",
                "create_menu": "Create menu",
                "menu_elements": "Menu Elements",
                "add_new_menu_element": "Add new menu element",
                "change_menu": "Change menu",
                "type": "Type",
                "href": "href",
                "sequence": "Sequence",
                "show_menu_element": "Show menu element",
                "menu": "Menu",
                "user_roles": "User Roles",
                "translations": "Translations",
                "dropdown_parent": "Dropdown parent",
                "icon": "Icon",
                "edit_menu_element": "Edit menu element",
                "other": "Other",
                "find_icon_class_in": "Find icon class in",
                "coreui_icons_docs": "CoreUI icons documentation",
                "create_menu_element": "Create menu element"
            },
            "roles": {
                "menu_roles": "Menu roles",
                "add_new_role": "Add new role",
                "name": "Name",
                "hierarchy": "Hierarchy",
                "created_at": "Created at",
                "updated_at": "Updated at",
                "create_new_role": "Create new role",
                "edit_role": "Edit role",
                "show_role": "Show role"
            },
            "media": {
                "media": "Media",
                "back": "Back",
                "new_folder": "New folder",
                "new_file": "New file",
                "rename": "Rename",
                "move": "Move",
                "delete": "Delete",
                "open": "Open",
                "copy": "Copy",
                "cropp": "Cropp",
                "move_folder": "Move folder",
                "move_up": "Move up",
                "move_file": "Move file",
                "rename_file": "Rename file",
                "rename_folder": "Rename folder",
                "file_info": "File info",
                "name": "Name",
                "real_name": "Real Name",
                "url": "URL",
                "mime_type": "mime type",
                "size": "Size",
                "created_at": "Created at",
                "updated_at": "Updated at",
                "delete_file": "Delete file",
                "are_you_sure": "Are you sure?",
                "delete_folder": "Delete folder",
                "cropp_image": "Cropp image",
                "delete_folder_warn": "If you delete a folder, all subfolders and files will also be deleted."
            },
            "bread": {
                "add_new_bread": "Add new BREAD",
                "go_to_resource": "Go to resource",
                "create_bread": "Create BREAD",
                "table_name_db": "Table name in database",
                "form_name": "Form name",
                "pagination": "Records on one page of table",
                "enable_show": "Enable Show button in table",
                "enable_edit": "Enable Edit button in table",
                "enable_add": "Enable Add button in table",
                "enable_delete": "Enable Delete button in table",
                "assign_to_roles": "Assign to roles",
                "visible_name": "Visible name",
                "field_type": "Field type",
                "relation_table": "Optional relation table name",
                "relation_column": "Optional column name in relation table - to print",
                "show_bread": "Show BREAD",
                "edit_bread": "Edit BREAD",
                "delete_bread": "Delete BREAD"
            },
            "resource": {
                "add_new": "Add new",
                "add": "Add",
                "show": "Show"
            },
            "select": "Select",
            "view": "View",
            "edit": "Edit",
            "delete": "Delete",
            "return": "Return",
            "save": "Save",
            "cancel": "Cancel",
            "are_you_sure": "Are you sure?"
        }
    },
    "pl": {
      "forms":{
        // dashboard
        "transl1": "Witaj w panelu",
        "transl2": "Szukaj...",
        "transl3": "Na stronie",
        "transl4": "Opcje",
        "transl5": "Brak danych do wyświetlenia.",
        "transl6": "Poprzednia",
        "transl7": "Pobierz",
        "transl8": "Następna",
        "transl9": "Wybierz",
        "transl10": "Wyświetlam",
        "transl11": "z",
        "transl12": "wyników",
        "transl13": "Sukces!",
        "transl14": "Rekord usunięty poprawnie!",
        "transl15": "Czy na pewno",
        "transl16": "Chcesz usunąć ten rekord?",
        "transl17": "Tak, usuń!",
        "transl18": "Anuluj",
        "transl19": "Chcesz dodać tego przewoźnika?",


        // carriers
        "transl20": "Przewoźnicy",
        "transl21": "Dodaj przewoźnika",
        "transl22": "Email",
        "transl23": "Imię",
        "transl24": "Nazwisko",
        "transl25": "Dodaj przewoźnika",
        "transl26": "Edytuj przewoźnika",
        "transl27": "Imię przewoźnika*",
        "transl28": "Nazwisko",
        "transl29": "Nazwisko przewoźnika*",
        "transl30": "To pole jest wymagane i musi zawierać minimum 3 znaki i maksimum 255 znaków",
        "transl31": "To pole jest wymagane i musi zawierać minimum 8 znaków. Wymagane znaki to: liczby, duże i małe litery",
        "transl32": "Pola hasła muszą być identyczne",
        "transl33": "Numer telefonu przewoźnika*",
        "transl34": "Adres email*",
        "transl35": "Hasło do logowania (minimum 8 znaków)",
        "transl36": "Ponownie hasło do logowania",
        "transl37": "Dane firmowe",
        "transl38": "Nazwa firmy*",
        "transl39": "Nazwa firmy*",
        "transl40": "Zaimportuj z GUS",
        "transl41": "Zaimportuj z VIES",
        "transl42": "NIP firmy*",
        "transl43": "Regon firmy",
        "transl44": "Email firmy (do powiadomień)*",
        "transl45": "Telefon firmy",
        "transl46": "Kod pocztowy przewoźnika*",
        "transl47": "Adres firmy*",
        "transl48": "Miasto firmy*",
        "transl49": "Konto bankowe firmy (PL)",
        "transl50": "Konto bankowe firmy (zagraniczne)",
        "transl51": "Notatka",
        "transl52": "Zapisz",
        "transl53": "Cofnij",
        "transl54": "Przypisane samochody",
        "transl55": "Dodaj samochód",
        "transl56": "Przypisani kierowcy",
        "transl57": "Dodaj kierowcę",
        "transl58": "Imię",
        "transl59": "Nazwisko",
        "transl60": "Producent",
        "transl61": "Nr rejestracyjny",
        "transl62": "Rekord zmieniony poprawnie!",
        "transl63": "Rekord dodany poprawnie!",
        "transl64": "Błąd",
        "transl65": "Proszę uzupełnić pole NIP",
        "transl66": "Proszę uzupełnić pole NIP",
        "transl67": "Ustawienia",


        // my profile
        "transl68": "Mój profil",
        "transl69": "Imię",
        "transl70": "Nazwisko",
        "transl71": "Telefon",
        "transl72": "Twoje obecne hasło",
        "transl73": "Zmiana hasła",

        // browsking history
        "transl74": "Imię użytkownika:",
        "transl75": "Nazwisko użytkownika:",
        "transl76": "Login użytkownika:",
        "transl77": "Akcja użytkownika:",
        "transl78": "Data:",
        "transl79": "Historia akcji użytkowników",
        "transl80": "Użytkownik",
        "transl81": "Data",

        // login history
        "transl82": "Historia logowania",
        "transl83": "Data logowania",

        // cars
        "transl84": "Nasze pojazdy",
        "transl85": "Edytuj samochód",
        "transl86": "Marka pojazdu*",
        "transl87": "Data zakupu",
        "transl88": "VIN pojazdu",
        "transl89": "Numer rejestracyjny*",
        "transl90": "Numer naczepy*",

        // drivers
        "transl91": "Dodaj kierowcę",
        "transl92": "Edytuj kierowcę",
        "transl93": "Imię*",
        "transl94": "Nazwisko*",
        "transl95": "Telefon*",
        "transl96": "Status",
        "transl97": "Adres email*",
        "transl98": "Data ważności licencji",
        "transl99": "Data ważności ubezpieczenia",
        "transl100": "Notatka",
        "transl101": "Przypisane pliki",
        "transl102": "Dodaj plik",
        "transl103": "Opis",
        "transl104": "Dodaj plik dla kierowcy:",
        "transl105": "Dodaj plik dla kierowcy:",
        "transl106": "Wymagany format pliku: jpg, jpeg, png, bmp",
        "transl107": "Pobierz plik",
        "transl108": "Opis pliku",
        "transl109": "Wpisz opis pliku",

        "transl110": "Godzina załadunku",
        "transl111": "Godzina rozładunku",

        "transl112": "Kod SWIFT",
        "transl113": "Nazwa banku PL",
        "transl114": "Nazwa banku zagranicznego",

        // dispatchers
        "transl115": "Dodaj dyspozytora",
        "transl116": "Edytuj dyspozytora",
        "transl117": "Dyspozytorzy",
        "transl118": "Dodaj dyspozytora",
        "transl119": "Imię*",
        "transl120": "Nazwisko*",
        "transl121": "Telefon*",
        "transl122": "Adres email*",
        "transl123": "Oddział*",
        "transl124": "Notatka",


        // admins
        "transl125": "Administratorzy",
        "transl126": "Dodaj administratora",
        "transl127": "Dodaj administratora",
        "transl128": "Edytuj administratora",
        "transl129": "Imię*",
        "transl130": "Nazwisko*",
        "transl131": "Telefon*",
        "transl132": "Adres email*",
        "transl133": "Notatka",


        // clients
        "transl134": "Klienci",
        "transl135": "Dodaj klienta",
        "transl136": "Nazwa firmy",
        "transl137": "Osoba kontaktowa",
        "transl138": "Dodaj klienta",
        "transl139": "Edytuj klienta",
        "transl140": "Adres siedziby",
        "transl141": "Firma*",
        "transl142": "Zaimportuj z GUS",
        "transl143": "Zaimportuj z VIES",
        "transl144": "Nip*",
        "transl145": "Regon",
        "transl146": "Osoba kontaktowa*",
        "transl147": "Telefon 1",
        "transl148": "Telefon 2",
        "transl149": "Telefon 3",
        "transl150": "Email 1",
        "transl151": "Email 2",
        "transl152": "Email 3",
        "transl153": "Kod pocztowy*",
        "transl154": "Miasto*",
        "transl155": "Ulica*",
        "transl156": "Kraj*",
        "transl157": "Województwo*",
        "transl158": "Adres korespondencyjny",
        "transl159": "Firma",
        "transl160": "Kod pocztowy",
        "transl161": "Miasto",
        "transl162": "Ulica",
        "transl163": "Kraj",
        "transl164": "Województwo",
        "transl165": "Adres fakturowy",
        "transl166": "Firma",
        "transl167": "Kod pocztowy",
        "transl168": "Miasto",
        "transl169": "Ulica",
        "transl170": "Kraj",
        "transl171": "Województwo",
        "transl172": "Notatka",


        // subsidiaries
        "transl173": "Nasze oddziały",
        "transl174": "Dodaj oddział",
        "transl175": "Nazwa",
        "transl176": "Nazwa oddziału*",
        "transl177": "Edytuj oddział",

        // dictionary
        "transl178": "Słowniki",
        "transl179": "Dodaj wpis",
        "transl180": "Nazwa",
        "transl181": "Typ",
        "transl182": "Dodaj rekord dla słownika",
        "transl183": "Edytuj rekord dla słownika",
        "transl184": "Nazwa rekordu*",
        "transl185": "Nazwa rekordu EN*",
        "transl186": "Typ",

        // tasks
        "transl187": "Zlecenia",
        "transl188": "Dodaj nowe zlecenie",
        "transl189": "Numer Speed",
        "transl190": "Numer zlecenia",
        "transl191": "Dodaj zlecenie",
        "transl192": "Edytuj zlecenie",
        "transl193": "Dane dyspozytora",
        "transl194": "Wybierz dyspozytora",
        "transl195": "Dane zlecenia",
        "transl196": "Zlecenie SPEED*",
        "transl197": "Numer zlecenia klienta",
        "transl198": "Data załadunku  transportu*",
        "transl199": "Data załadunku",
        "transl200": "Godzina załadunku",
        "transl201": "Data rozładunku transportu*",
        "transl202": "Data rozładunku",
        "transl203": "Godzina rozładunku",
        "transl204": "Adres wyjazdu*",
        "transl205": "Adres dostawy*",
        "transl206": "Opcja neutralna",
        "transl207": "Rodzaj dostawy",
        "transl208": "Status dostawy",
        "transl209": "Ukryj dane na wydruku",
        "transl210": "Wybierz samochód",
        "transl211": "Wybierz kierowcę",
        "transl212": "Dane przewoźnika",
        "transl213": "Uzupełnij",
        "transl214": "Nazwa przewoźnika*",
        "transl215": "NIP przewoźnika*",
        "transl216": "Email przewoźnika*",
        "transl217": "Telefon przewoźnika*",
        "transl218": "Kod pocztowy przewoźnika*",
        "transl219": "Adres przewoźnika*",
        "transl220": "Miasto przewoźnika*",
        "transl221": "Wybierz kierowcę",
        "transl222": "Dodaj kierowcę",
        "transl223": "Wybierz samochód",
        "transl224": "Uwagi do zamówienia",
        "transl225": "Osoba kontaktowa",
        "transl226": "Rodzaj przewożonego towaru",
        "transl227": "Wymagane dokumenty",
        "transl228": "Wymagane pliki dla dostawcy",
        "transl229": "Historia zmian",
        "transl230": "Pobierz CRM",
        "transl231": "Historia statusów",
        "transl232": "Status",
        "transl233": "Użytkownik",
        "transl234": "Data",
        "transl235": "Akcja",
        "transl236": "Dostawa",
        "transl237": "Pliki",
        "transl238": "Imię i nazwisko osoby odbierającej",
        "transl239": "Wyczyść",
        "transl240": "Status dostawy",
        "transl241": "Uwagi do dostawy",
        "transl242": "Podpis odbiorcy",
        "transl243": "Wybierz przewoźnika",
        "transl244": "Email",
        "transl245": "Imię",
        "transl246": "Nazwisko",
        "transl247": "Dodaj kierowcę",
        "transl248": "Imię kierowcy*",
        "transl249": "Nazwisko kierowcy*",
        "transl250": "Telefon",
        "transl251": "Dodaj kierowcę",
        "transl252": "Czy zapisać dane",
        "transl253": "zlecenia w bazie?",
        "transl254": "Tak, zapisz",
        "transl255": "Anuluj",
        "transl256": "Czy zapisać nowego",
        "transl257": "kierowcę  w bazie?",
        "transl258": "Błąd",
        "transl259": "Proszę uzupełnić wszystkie pola oznaczone gwiazdką",
        "transl260": "Wymagane dokumenty do dodania",
        "transl261": "Wymagane pliki dla dostawcy do dodania",
        "transl262": "Pliki",
        "transl263": "Dodaj plik",
        "transl264": "Wymagane dokumenty do dodania",
        "transl265": "Wymagane pliki dla dostawcy do dodania",
        "transl266": "Opis",
        "transl267": "Kategoria",
        "transl268": "Twórca",
        "transl269": "Dodaj plik w formacie: jpg, jpeg, png, bmp, pdf, xml, csv, doc, docx, txt, rtf",
        "transl270": "Wybierz kategorię pliku",
        "transl271": "Opis pliku",
        "transl272": "Dodaj plik",
        "transl273": "Wpisz opis dodawanego pliku",
        "transl274": "Dodaj kierowcę",
        "transl275": "Sukces",
        "transl276": "Plik dodany poprawnie!",
        "transl277": "Nastąpił problem z uploadem pliku na serwer. Proszę sprawdzić poprawność pliku",
        "transl278": "Proszę wybrać plik",
        "transl279": "Czy zapisać aktualizację",
        "transl280": "zlecenia w bazie?",
        "transl281": "Email",
        "transl282": "Imię",
        "transl283": "Nazwisko",
        "transl284": "Ogólne",
        "transl285": "Historia zmian",
        "transl286": "Dostawa",
        "transl287": "Pliki",
        "transl288": "Wyloguj",
        "transl289": "xxxxxxxxxx",
        "transl290": "xxxxxxxxxx",
        "transl291": "xxxxxxxxxx",
        "transl292": "xxxxxxxxxx",
        "transl293": "xxxxxxxxxx",
        "transl294": "xxxxxxxxxx",
        "transl295": "xxxxxxxxxx",
        "transl296": "xxxxxxxxxx",
        "transl297": "xxxxxxxxxx",
        "transl298": "xxxxxxxxxx",
        "transl299": "xxxxxxxxxx",
        "transl300": "xxxxxxxxxx",
        "transl301": "xxxxxxxxxx",
        "transl302": "xxxxxxxxxx",
        "transl303": "xxxxxxxxxx",
        "transl304": "xxxxxxxxxx",
        "transl305": "xxxxxxxxxx",
        "transl306": "xxxxxxxxxx",
        "transl307": "xxxxxxxxxx",
        "transl308": "xxxxxxxxxx",
        "transl309": "xxxxxxxxxx",
        "transl310": "xxxxxxxxxx",
        "transl311": "xxxxxxxxxx",
        "transl312": "xxxxxxxxxx",
        "transl313": "xxxxxxxxxx",
        "transl314": "xxxxxxxxxx",
        "transl315": "xxxxxxxxxx",
        "transl316": "xxxxxxxxxx",
        "transl317": "xxxxxxxxxx",
        "transl318": "xxxxxxxxxx",
        "transl319": "xxxxxxxxxx",
        "transl320": "xxxxxxxxxx",
        "transl321": "xxxxxxxxxx",
        "transl322": "xxxxxxxxxx",
        "transl323": "xxxxxxxxxx",
        "transl324": "xxxxxxxxxx",
        "transl325": "xxxxxxxxxx",
        "transl326": "xxxxxxxxxx",
        "transl327": "xxxxxxxxxx",
        "transl328": "xxxxxxxxxx",
        "transl329": "xxxxxxxxxx",
        "transl330": "xxxxxxxxxx",
        "transl331": "xxxxxxxxxx",
        "transl332": "xxxxxxxxxx",
        "transl333": "xxxxxxxxxx",
        "transl334": "xxxxxxxxxx",
        "transl335": "xxxxxxxxxx",
        "transl336": "xxxxxxxxxx",
        "transl337": "xxxxxxxxxx",
        "transl338": "xxxxxxxxxx",
        "transl339": "xxxxxxxxxx",
        "transl340": "xxxxxxxxxx",
        "transl341": "xxxxxxxxxx",
        "transl342": "xxxxxxxxxx",
        "transl343": "xxxxxxxxxx",
        "transl344": "xxxxxxxxxx",
        "transl345": "xxxxxxxxxx",
        "transl346": "xxxxxxxxxx",
        "transl347": "xxxxxxxxxx",
        "transl348": "xxxxxxxxxx",
        "transl349": "xxxxxxxxxx",
        "transl350": "xxxxxxxxxx",
        "transl351": "xxxxxxxxxx",
        "transl352": "xxxxxxxxxx",
        "transl353": "xxxxxxxxxx",
        "transl354": "xxxxxxxxxx",
        "transl355": "xxxxxxxxxx",
        "transl356": "xxxxxxxxxx",
        "transl357": "xxxxxxxxxx",
        "transl358": "xxxxxxxxxx",
        "transl359": "xxxxxxxxxx",
        "transl360": "xxxxxxxxxx",
        "transl361": "xxxxxxxxxx",
        "transl362": "xxxxxxxxxx",
        "transl363": "xxxxxxxxxx",
        "transl364": "xxxxxxxxxx",
        "transl365": "xxxxxxxxxx",
        "transl366": "xxxxxxxxxx",
        "transl367": "xxxxxxxxxx",
        "transl368": "xxxxxxxxxx",
        "transl369": "xxxxxxxxxx",
        "transl370": "xxxxxxxxxx",
        "transl371": "xxxxxxxxxx",
        "transl372": "xxxxxxxxxx",
        "transl373": "xxxxxxxxxx",
        "transl374": "xxxxxxxxxx",
        "transl375": "xxxxxxxxxx",
        "transl376": "xxxxxxxxxx",
        "transl377": "xxxxxxxxxx",
        "transl378": "xxxxxxxxxx",
        "transl379": "xxxxxxxxxx",
        "transl380": "xxxxxxxxxx",
        "transl381": "xxxxxxxxxx",
        "transl382": "xxxxxxxxxx",
        "transl383": "xxxxxxxxxx",
        "transl384": "xxxxxxxxxx",
        "transl385": "xxxxxxxxxx",
        "transl386": "xxxxxxxxxx",
        "transl387": "xxxxxxxxxx",
        "transl388": "xxxxxxxxxx",
        "transl389": "xxxxxxxxxx",
        "transl390": "xxxxxxxxxx",
        "transl391": "xxxxxxxxxx",
        "transl392": "xxxxxxxxxx",
        "transl393": "xxxxxxxxxx",
        "transl394": "xxxxxxxxxx",
        "transl395": "xxxxxxxxxx",
        "transl396": "xxxxxxxxxx",
        "transl397": "xxxxxxxxxx",
        "transl398": "xxxxxxxxxx",
        "transl399": "xxxxxxxxxx",
        "transl400": "xxxxxxxxxx",
        "transl401": "xxxxxxxxxx",
        "transl402": "xxxxxxxxxx",
        "transl403": "xxxxxxxxxx",
        "transl404": "xxxxxxxxxx",
        "transl405": "xxxxxxxxxx",
        "transl406": "xxxxxxxxxx",
        "transl407": "xxxxxxxxxx",
        "transl408": "xxxxxxxxxx",
        "transl409": "xxxxxxxxxx",
        "transl410": "xxxxxxxxxx",
        "transl411": "xxxxxxxxxx",
        "transl412": "xxxxxxxxxx",
        "transl413": "xxxxxxxxxx",
        "transl414": "xxxxxxxxxx",
        "transl415": "xxxxxxxxxx",
        "transl416": "xxxxxxxxxx",
        "transl417": "xxxxxxxxxx",
        "transl418": "xxxxxxxxxx",
        "transl419": "xxxxxxxxxx",
        "transl420": "xxxxxxxxxx",
        "transl421": "xxxxxxxxxx",
        "transl422": "xxxxxxxxxx",
        "transl423": "xxxxxxxxxx",
        "transl424": "xxxxxxxxxx",
        "transl425": "xxxxxxxxxx",
        "transl426": "xxxxxxxxxx",
        "transl427": "xxxxxxxxxx",
        "transl428": "xxxxxxxxxx",
        "transl429": "xxxxxxxxxx",
        "transl430": "xxxxxxxxxx",
        "transl431": "xxxxxxxxxx",
        "transl432": "xxxxxxxxxx",
        "transl433": "xxxxxxxxxx",
        "transl434": "xxxxxxxxxx",
        "transl435": "xxxxxxxxxx",
        "transl436": "xxxxxxxxxx",
        "transl437": "xxxxxxxxxx",
        "transl438": "xxxxxxxxxx",
        "transl439": "xxxxxxxxxx",
        "transl440": "xxxxxxxxxx",
        "transl441": "xxxxxxxxxx",
        "transl442": "xxxxxxxxxx",
        "transl443": "xxxxxxxxxx",
        "transl444": "xxxxxxxxxx",
        "transl445": "xxxxxxxxxx",
        "transl446": "xxxxxxxxxx",
        "transl447": "xxxxxxxxxx",
        "transl448": "xxxxxxxxxx",
        "transl449": "xxxxxxxxxx",
        "transl450": "xxxxxxxxxx",
        "transl451": "xxxxxxxxxx",
        "transl452": "xxxxxxxxxx",
        "transl453": "xxxxxxxxxx",
        "transl454": "xxxxxxxxxx",
        "transl455": "xxxxxxxxxx",
        "transl456": "xxxxxxxxxx",
        "transl457": "xxxxxxxxxx",
        "transl458": "xxxxxxxxxx",
        "transl459": "xxxxxxxxxx",
        "transl460": "xxxxxxxxxx",
        "transl461": "xxxxxxxxxx",
        "transl462": "xxxxxxxxxx",
        "transl463": "xxxxxxxxxx",
        "transl464": "xxxxxxxxxx",
        "transl465": "xxxxxxxxxx",
        "transl466": "xxxxxxxxxx",
        "transl467": "xxxxxxxxxx",
        "transl468": "xxxxxxxxxx",
        "transl469": "xxxxxxxxxx",
        "transl470": "xxxxxxxxxx",
        "transl471": "xxxxxxxxxx",
        "transl472": "xxxxxxxxxx",
        "transl473": "xxxxxxxxxx",
        "transl474": "xxxxxxxxxx",
        "transl475": "xxxxxxxxxx",
        "transl476": "xxxxxxxxxx",
        "transl477": "xxxxxxxxxx",
        "transl478": "xxxxxxxxxx",
        "transl479": "xxxxxxxxxx",
        "transl480": "xxxxxxxxxx",
        "transl481": "xxxxxxxxxx",
        "transl482": "xxxxxxxxxx",
        "transl483": "xxxxxxxxxx",
        "transl484": "xxxxxxxxxx",
        "transl485": "xxxxxxxxxx",
        "transl486": "xxxxxxxxxx",
        "transl487": "xxxxxxxxxx",
        "transl488": "xxxxxxxxxx",
        "transl489": "xxxxxxxxxx",
        "transl490": "xxxxxxxxxx",
        "transl491": "xxxxxxxxxx",
        "transl492": "xxxxxxxxxx",
        "transl493": "xxxxxxxxxx",
        "transl494": "xxxxxxxxxx",
        "transl495": "xxxxxxxxxx",
        "transl496": "xxxxxxxxxx",
        "transl497": "xxxxxxxxxx",
        "transl498": "xxxxxxxxxx",
        "transl499": "xxxxxxxxxx",
        "transl500": "xxxxxxxxxx",
        "transl501": "xxxxxxxxxx",
        "transl502": "xxxxxxxxxx",
        "transl503": "xxxxxxxxxx",
        "transl504": "xxxxxxxxxx",
        "transl505": "xxxxxxxxxx",
        "transl506": "xxxxxxxxxx",
        "transl507": "xxxxxxxxxx",
        "transl508": "xxxxxxxxxx",
        "transl509": "xxxxxxxxxx",
        "transl510": "xxxxxxxxxx",
        "transl511": "xxxxxxxxxx",
        "transl512": "xxxxxxxxxx",
        "transl513": "xxxxxxxxxx",
        "transl514": "xxxxxxxxxx",
        "transl515": "xxxxxxxxxx",
        "transl516": "xxxxxxxxxx",
        "transl517": "xxxxxxxxxx",
        "transl518": "xxxxxxxxxx",
        "transl519": "xxxxxxxxxx",
        "transl520": "xxxxxxxxxx",
        "transl521": "xxxxxxxxxx",
        "transl522": "xxxxxxxxxx",
        "transl523": "xxxxxxxxxx",
        "transl524": "xxxxxxxxxx",
        "transl525": "xxxxxxxxxx",
        "transl526": "xxxxxxxxxx",
        "transl527": "xxxxxxxxxx",
        "transl528": "xxxxxxxxxx",
        "transl529": "xxxxxxxxxx",
        "transl530": "xxxxxxxxxx",
        "transl531": "xxxxxxxxxx",
        "transl532": "xxxxxxxxxx",
        "transl533": "xxxxxxxxxx",
        "transl534": "xxxxxxxxxx",
        "transl535": "xxxxxxxxxx",
        "transl536": "xxxxxxxxxx",
        "transl537": "xxxxxxxxxx",
        "transl538": "xxxxxxxxxx",
        "transl539": "xxxxxxxxxx",
        "transl540": "xxxxxxxxxx",
        "transl541": "xxxxxxxxxx",
        "transl542": "xxxxxxxxxx",
        "transl543": "xxxxxxxxxx",
        "transl544": "xxxxxxxxxx",
        "transl545": "xxxxxxxxxx",
        "transl546": "xxxxxxxxxx",
        "transl547": "xxxxxxxxxx",
        "transl548": "xxxxxxxxxx",
        "transl549": "xxxxxxxxxx",
        "transl550": "xxxxxxxxxx",
        "transl551": "xxxxxxxxxx",
        "transl552": "xxxxxxxxxx",
        "transl553": "xxxxxxxxxx",
        "transl554": "xxxxxxxxxx",
        "transl555": "xxxxxxxxxx",
        "transl556": "xxxxxxxxxx",
        "transl557": "xxxxxxxxxx",
        "transl558": "xxxxxxxxxx",
        "transl559": "xxxxxxxxxx",
        "transl560": "xxxxxxxxxx",
        "transl561": "xxxxxxxxxx",
        "transl562": "xxxxxxxxxx",
        "transl563": "xxxxxxxxxx",
        "transl564": "xxxxxxxxxx",
        "transl565": "xxxxxxxxxx",
        "transl566": "xxxxxxxxxx",
        "transl567": "xxxxxxxxxx",
        "transl568": "xxxxxxxxxx",
        "transl569": "xxxxxxxxxx",
        "transl570": "xxxxxxxxxx",
        "transl571": "xxxxxxxxxx",
        "transl572": "xxxxxxxxxx",
        "transl573": "xxxxxxxxxx",
        "transl574": "xxxxxxxxxx",
        "transl575": "xxxxxxxxxx",
        "transl576": "xxxxxxxxxx",
        "transl577": "xxxxxxxxxx",
        "transl578": "xxxxxxxxxx",
        "transl579": "xxxxxxxxxx",
        "transl580": "xxxxxxxxxx",
        "transl581": "xxxxxxxxxx",
        "transl582": "xxxxxxxxxx",
        "transl583": "xxxxxxxxxx",
        "transl584": "xxxxxxxxxx",
        "transl585": "xxxxxxxxxx",
        "transl586": "xxxxxxxxxx",
        "transl587": "xxxxxxxxxx",
        "transl588": "xxxxxxxxxx",
        "transl589": "xxxxxxxxxx",
        "transl590": "xxxxxxxxxx",
        "transl591": "xxxxxxxxxx",
        "transl592": "xxxxxxxxxx",
        "transl593": "xxxxxxxxxx",
        "transl594": "xxxxxxxxxx",
        "transl595": "xxxxxxxxxx",
        "transl596": "xxxxxxxxxx",
        "transl597": "xxxxxxxxxx",
        "transl598": "xxxxxxxxxx",
        "transl599": "xxxxxxxxxx",
        "transl600": "xxxxxxxxxx",
        "transl601": "xxxxxxxxxx",
        "transl602": "xxxxxxxxxx",
        "transl603": "xxxxxxxxxx",
        "transl604": "xxxxxxxxxx",
        "transl605": "xxxxxxxxxx",
        "transl606": "xxxxxxxxxx",
        "transl607": "xxxxxxxxxx",
        "transl608": "xxxxxxxxxx",
        "transl609": "xxxxxxxxxx",
        "transl610": "xxxxxxxxxx",
        "transl611": "xxxxxxxxxx",
        "transl612": "xxxxxxxxxx",
        "transl613": "xxxxxxxxxx",
        "transl614": "xxxxxxxxxx",
        "transl615": "xxxxxxxxxx",
        "transl616": "xxxxxxxxxx",
        "transl617": "xxxxxxxxxx",
        "transl618": "xxxxxxxxxx",
        "transl619": "xxxxxxxxxx",
        "transl620": "xxxxxxxxxx",
        "transl621": "xxxxxxxxxx",
        "transl622": "xxxxxxxxxx",
        "transl623": "xxxxxxxxxx",
        "transl624": "xxxxxxxxxx",
        "transl625": "xxxxxxxxxx",
        "transl626": "xxxxxxxxxx",
        "transl627": "xxxxxxxxxx",
        "transl628": "xxxxxxxxxx",
        "transl629": "xxxxxxxxxx",
        "transl630": "xxxxxxxxxx",
        "transl631": "xxxxxxxxxx",
        "transl632": "xxxxxxxxxx",
        "transl633": "xxxxxxxxxx",
        "transl634": "xxxxxxxxxx",
        "transl635": "xxxxxxxxxx",
        "transl636": "xxxxxxxxxx",
        "transl637": "xxxxxxxxxx",
        "transl638": "xxxxxxxxxx",
        "transl639": "xxxxxxxxxx",
        "transl640": "xxxxxxxxxx",
        "transl641": "xxxxxxxxxx",
        "transl642": "xxxxxxxxxx",
        "transl643": "xxxxxxxxxx",
        "transl644": "xxxxxxxxxx",
        "transl645": "xxxxxxxxxx",
        "transl646": "xxxxxxxxxx",
        "transl647": "xxxxxxxxxx",
        "transl648": "xxxxxxxxxx",
        "transl649": "xxxxxxxxxx",
        "transl650": "xxxxxxxxxx",
        "transl651": "xxxxxxxxxx",
        "transl652": "xxxxxxxxxx",
        "transl653": "xxxxxxxxxx",
        "transl654": "xxxxxxxxxx",
        "transl655": "xxxxxxxxxx",
        "transl656": "xxxxxxxxxx",
        "transl657": "xxxxxxxxxx",
        "transl658": "xxxxxxxxxx",
        "transl659": "xxxxxxxxxx",
        "transl660": "xxxxxxxxxx",
        "transl661": "xxxxxxxxxx",
        "transl662": "xxxxxxxxxx",
        "transl663": "xxxxxxxxxx",
        "transl664": "xxxxxxxxxx",
        "transl665": "xxxxxxxxxx",
        "transl666": "xxxxxxxxxx",
        "transl667": "xxxxxxxxxx",
        "transl668": "xxxxxxxxxx",
        "transl669": "xxxxxxxxxx",
        "transl670": "xxxxxxxxxx",
        "transl671": "xxxxxxxxxx",
        "transl672": "xxxxxxxxxx",
        "transl673": "xxxxxxxxxx",
        "transl674": "xxxxxxxxxx",
        "transl675": "xxxxxxxxxx",
        "transl676": "xxxxxxxxxx",
        "transl677": "xxxxxxxxxx",
        "transl678": "xxxxxxxxxx",
        "transl679": "xxxxxxxxxx",
        "transl680": "xxxxxxxxxx",
        "transl681": "xxxxxxxxxx",
        "transl682": "xxxxxxxxxx",
        "transl683": "xxxxxxxxxx",
        "transl684": "xxxxxxxxxx",
        "transl685": "xxxxxxxxxx",
        "transl686": "xxxxxxxxxx",
        "transl687": "xxxxxxxxxx",
        "transl688": "xxxxxxxxxx",
        "transl689": "xxxxxxxxxx",
        "transl690": "xxxxxxxxxx",
        "transl691": "xxxxxxxxxx",
        "transl692": "xxxxxxxxxx",
        "transl693": "xxxxxxxxxx",
        "transl694": "xxxxxxxxxx",
        "transl695": "xxxxxxxxxx",
        "transl696": "xxxxxxxxxx",
        "transl697": "xxxxxxxxxx",
        "transl698": "xxxxxxxxxx",
        "transl699": "xxxxxxxxxx",
        "transl700": "xxxxxxxxxx",




        // drivers

        // dispatchers

        // administrators

        // tasks

        // login-history

        // browsing-history

        // clients

        // out-cars

        // our-subsidiaries

        // dictionaries


      },
        "days": {
            "monday": "Poniedziałek",
            "tuesday": "Wtorek",
            "wednesday": "Środa",
            "thrusday": "Czwartek",
            "friday": "Piątek",
            "saturday": "Sobota",
            "sunday": "Niedziela"
        },
        "dashboard": {
            "action": "Akcja",
            "another_action": "Kolejna akcja",
            "something_else_here": "Tutaj coś jeszcze",
            "disabled_action": "Zablokowana akcja",
            "members_online": "Członków online",
            "Traffic": "Ruch",
            "september": "Wrzesień",
            "day": "Dzień",
            "month": "Miesiąc",
            "year": "Rok",
            "users": "Użytkowników",
            "visits": "Wizyt",
            "unique": "Unikatowych",
            "pageviews": "Odświerzeń",
            "new_users": "Nowi użytkownicy",
            "bounce_rate": "Wskaźnik odrzuceń",
            "friends": "przyjaciół",
            "feeds": "feeds",
            "followers": "podążających",
            "tweets": "tweetów",
            "contacts": "kontaktów",
            "traffic_&_sales": "Ruch i Sprzedaż",
            "new_clients": "Nowi klienci",
            "recurring_clients": "Powtarzający się klienci",
            "organic": "Organiczny",
            "male": "Mężczyźni",
            "female": "Kobiety",
            "organic_search": "Wyszukiwanie organiczne",
            "user": "Użytkownik",
            "country": "Kraj",
            "usage": "Zużycie",
            "payment_method": "Metoda płatności",
            "activity": "Aktywność",
            "new": "Nowy",
            "recurring": "Powracający",
            "registered": "Zarejestrowany",
            "last_login": "Ostatnie logowanie",
            "time": {
                "sec_ago": "sekund temu",
                "minutes_ago": "minut temu",
                "hour_ago": "godzinę temu",
                "last_month": "Ostatni miesiąc",
                "last_week": "Ostatni tydzień",
                "yesterday": "Wczoraj"
            }
        },
        "coreuiforms": {
            "notes": {
                "note": "Notatka",
                "notes": "Notatki",
                "create_note": "Utwórz notatkę",
                "add_note": "Dodaj notatkę",
                "author": "Autor",
                "title": "Tytuł",
                "content": "Zawartość",
                "applies_to_date": "Dotyczy daty",
                "status": "Status",
                "note_type": "Typ notatki"
            },
            "users": {
                "user": "Użytkownik",
                "users": "Użytkownicy",
                "username": "Nazwa użytkownika",
                "email": "E-mail",
                "roles": "Role",
                "email_verified_at": "Data weryfikacji E-mail"
            },
            "menu": {
                "menu_list": "Lista menu",
                "add_new_menu": "Dodaj nowe menu",
                "name": "Nazwa",
                "create_menu": "Utwórz menu",
                "menu_elements": "Elementy menu",
                "add_new_menu_element": "Dodaj nowy element menu",
                "change_menu": "Zmień menu",
                "type": "Typ",
                "href": "href",
                "sequence": "Kolejność",
                "show_menu_element": "Pokaż element menu",
                "menu": "Menu",
                "user_roles": "Role użytkowników",
                "translations": "Tłumaczenia",
                "dropdown_parent": "Rodzic dropdown",
                "icon": "Ikona",
                "edit_menu_element": "Edytuj element menu",
                "other": "Inne",
                "find_icon_class_in": "Znajdź klasę ikony w",
                "coreui_icons_docs": "Dokumentacji ikon CoreUI",
                "create_menu_element": "Utwórz element menu"
            },
            "roles": {
                "menu_roles": "Role menu",
                "add_new_role": "Dodaj nową role",
                "name": "Nazwa",
                "hierarchy": "Hierarchia",
                "created_at": "Data utworzenia",
                "updated_at": "Data aktualizacji",
                "create_new_role": "Utwórz nową rolę",
                "edit_role": "Edytuj role",
                "show_role": "Pokaż role"
            },
            "media": {
                "media": "Media",
                "back": "Powrót",
                "new_folder": "Nowy folder",
                "new_file": "Nowy plik",
                "rename": "Zmień nazwę",
                "move": "Przemieść",
                "delete": "Usuń",
                "open": "Otwórz",
                "copy": "Kopiuj",
                "cropp": "Przytnij",
                "move_folder": "Przemieść folder",
                "move_up": "Przemieść w górę",
                "move_file": "Przemieść plik",
                "rename_file": "Zmień nazwę pliku",
                "rename_folder": "Zmień nazwę folderu",
                "file_info": "Informacje o pliku",
                "name": "Nazwa",
                "real_name": "Prawdziwa nazwa",
                "url": "URL",
                "mime_type": "mime type",
                "size": "Rozmiar",
                "created_at": "Data utworzenia",
                "updated_at": "Data aktualizacji",
                "delete_file": "Usuń plik",
                "are_you_sure": "Czy na pewno?",
                "delete_folder": "Usuń folder",
                "cropp_image": "Przytnij obraz",
                "delete_folder_warn": "Jeżeli usuniesz folder, wszystkie podfoldery i zwarte w nich pliki zostaną również usunięte."
            },
            "bread": {
                "add_new_bread": "Dodaj nowy BREAD",
                "go_to_resource": "Przejdź do zasobu",
                "create_bread": "Utwórz BREAD",
                "table_name_db": "Nazwa tabeli w bazie danych",
                "form_name": "Nazwa formularza",
                "pagination": "Rekordów na jednej stronie",
                "enable_show": "Dołącz przycisk Show do formularza",
                "enable_edit": "Dołącz przycisk Edit do formularza",
                "enable_add": "Dołącz przycisk Add do formularza",
                "enable_delete": "Dołącz przycisk Delete do formularza",
                "assign_to_roles": "Przypisz do roli",
                "visible_name": "Widoczna nazwa",
                "field_type": "Typ pola",
                "relation_table": "Opcjonalna zawa relacyjnej tabeli",
                "relation_column": "Opcjonalna nazwa kolumny do wyświetlenia z relacyjnej tabeli",
                "show_bread": "Pokaż BREAD",
                "edit_bread": "Edytuj BREAD",
                "delete_bread": "Usuń BREAD"
            },
            "resource": {
                "add_new": "Dodaj",
                "add": "Dodaj",
                "show": "Pokaż"
            },
            "select": "Wybierz",
            "view": "Podgląd",
            "edit": "Edycja",
            "delete": "Usuń",
            "return": "Powrót",
            "save": "Zapisz",
            "cancel": "Anuluj",
            "are_you_sure": "Czy jesteś pewny?"
        }
    }
}
