<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>

<style scoped>
.invalid input,
.invalid textarea,
.invalid select,
.invalid checkbox,
.invalid .cke_chrome {
  border: 1px solid red !important;
}

.active {
  background-color: #4CAF50 !important;
  color: white;
}

.page-item .active {
  background-color: #ddd;
}

.bg-login-bg{
  background: url(/bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  background-size: cover;
}

</style>

