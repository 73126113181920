<template>
  <div>
    <div class="row mb-3">
<!--      <div class="col-3">-->
<!--        <div class="input-group">-->
<!--          <input-->
<!--            v-model="search"-->
<!--            class="form-control"-->
<!--            placeholder="Szukaj..."-->
<!--            type="text"-->
<!--            @keyup.enter="handleSearch"-->
<!--          >-->
<!--          <div class="input-group-append">-->
<!--            <button class="btn btn-primary" type="button" @click.prevent="handleSearch">-->
<!--              <font-awesome-icon icon="fas fa-search"/>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="col-2">
        <div class="input-group">
          <label for="pageOption" class="mt-2 mr-2">{{ $t('forms.transl3')  }}</label>
          <select class="form-control" v-model="perPage" @change="handlePerPage" id="pageOption">
            <option v-for="page in pageOptions" :key="page" :value="page">{{ page }}</option>
          </select>
        </div>
      </div>
    </div>


    <table class="table table-hover">
      <thead>
      <tr>
        <th class="table-head">#</th>
        <th v-for="(label, column) in headers" :key="column" @click="sortByColumn(column)" class="table-head">
          {{ label | columnHead }}
          <span v-if="column === sortedColumn">
                            <font-awesome-icon v-if="order === 'asc'"  icon="fas fa-angle-up" />
                            <font-awesome-icon v-else icon="fas fa-angle-down" />
            </span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="" v-if="tableData.length === 0">
        <td class="lead text-center" :colspan="columns.length + 1">{{ $t('forms.transl5')  }}</td>
      </tr>
      <tr v-for="(data, key1) in tableData" :key="data.id" class="m-datatable__row" v-else>
        <td>{{ serialNumber(key1) }}</td>
        <td v-for="(value, key) in data"><div v-html="value">{{ value }}</div></td>
      </tr>
      </tbody>
    </table>
    <nav v-if="pagination && tableData.length > 0">
      <ul class="pagination">
        <li class="page-item" :class="{'disabled' : currentPage === 1}">
          <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">{{ $t('forms.transl6')  }}</a>
        </li>
        <li v-for="page in pagesNumber" class="page-item"
            :class="{'active': page == pagination.meta.current_page}">
          <a href="javascript:void(0)" @click.prevent="changePage(page)" class="page-link">{{ page }}</a>
        </li>
        <li class="page-item" :class="{'disabled': currentPage === pagination.meta.last_page }">
          <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">{{ $t('forms.transl8')  }}</a>
        </li>
        <span style="margin-top: 8px;"> &nbsp; <i>{{ $t('forms.transl10')  }} {{ pagination.data.length }} {{ $t('forms.transl11')  }} {{ pagination.meta.total }} {{ $t('forms.transl12')  }}.</i></span>
      </ul>
    </nav>

  </div>
</template>

<script type="text/ecmascript-6">
import axios from 'axios';
import Vue from 'vue';
import 'vuejs-datatable/dist/themes/bootstrap-4.esm';
import {
  VuejsDatatableFactory,
  IDataFnParams,
  IDisplayHandlerParam,
  ITableContentParam,
  TColumnsDefinition,
  VueDatatable
} from 'vuejs-datatable';

Vue.use(VuejsDatatableFactory, VueDatatable);
import Swal from 'sweetalert2';

export default {
  props: {
    fetchUrl: {type: String, required: true},
    columns: {type: Array, required: true},
    headers: {type: Object, required: true},
    routeName: {type: String, required: true},
    showUrl: {type: String, required: false},
  },
  data() {
    return {
      tableData: [],
      url: '',
      pagination: {
        meta: {to: 1, from: 1}
      },
      offset: 4,
      currentPage: 1,
      perPage: 100,
      sortedColumn: this.columns[0],
      order: 'asc',
      search: '',
      pageOptions: [100, 200, 500, 1000],
    }
  },
  watch: {
    fetchUrl: {
      handler: function (fetchUrl) {
        this.url = fetchUrl
      },
      immediate: true
    }
  },
  created() {
    console.log(this.fetchUrl);
    return this.fetchData()
  },
  computed: {
    /**
     * Get the pages number array for displaying in the pagination.
     * */
    pagesNumber() {
      if (!this.pagination.meta.to) {
        return []
      }
      let from = this.pagination.meta.current_page - this.offset
      if (from < 1) {
        from = 1
      }
      let to = from + (this.offset * 2)
      if (to >= this.pagination.meta.last_page) {
        to = this.pagination.meta.last_page
      }
      let pagesArray = []
      for (let page = from; page <= to; page++) {
        pagesArray.push(page)
      }
      return pagesArray
    },
    /**
     * Get the total data displayed in the current page.
     * */
    totalData() {
      return (this.pagination.meta.to - this.pagination.meta.from) + 1
    }
  },
  methods: {
    fetchData() {
      let dataFetchUrl = `${this.url}&page=${this.currentPage}&column=${this.sortedColumn}&order=${this.order}&per_page=${this.perPage}&search=${this.search}`
      axios.get(dataFetchUrl)
        .then(({data}) => {
          this.pagination = data
          this.tableData = data.data
        }).catch(error => this.tableData = [])
    },
    /**
     * Get the serial number.
     * @param key
     * */
    serialNumber(key) {
      return (this.currentPage - 1) * this.perPage + 1 + key
    },
    /**
     * Change the page.
     * @param pageNumber
     */
    changePage(pageNumber) {
      this.currentPage = pageNumber
      this.fetchData()
    },
    /**
     * Sort the data by column.
     * */
    sortByColumn(column) {
      if (column === this.sortedColumn) {
        this.order = (this.order === 'asc') ? 'desc' : 'asc'
      } else {
        this.sortedColumn = column
        this.order = 'asc'
      }
      this.fetchData()
    },
    handleSearch() {
      this.fetchData()
    },
    handlePerPage($event) {
      this.perPage = $event.target.value;
      this.fetchData()
    },

    ///
  },
  filters: {
    columnHead(value) {
      return value.split('_').join(' ').toUpperCase()
    }
  },
  name: 'DataTableNoneOptionsAndSearch'
}
</script>

<style scoped>
</style>
